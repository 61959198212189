import * as React from 'react';

interface Props {
    strokeColor: string;
    transform: string;
    strokeWidth?: number;
}

const SvgDownArrowSm: React.FC<Props> = ({ strokeColor, transform, strokeWidth }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.67 15.33"
            style={{ transform: transform }}
        >
            <g data-name="Layer 2">
                <polyline
                    points="27.66 1 14.33 14.33 1 1"
                    fill="none"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth ? strokeWidth : 2}
                />
            </g>
        </svg>
    );
};

export default SvgDownArrowSm;
