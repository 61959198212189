import React, { useContext } from 'react';
//@ts-ignore
import * as style from './CountrySelectOverlay.module.css';
import RichText from '../Global/RichText';
import CtaLink from '../Global/CtaLink';
import { graphql } from 'gatsby';
import Dropdown from 'react-dropdown';
import SvgDownArrowSm from '../Global/ShowMoreArrow/SvgDownArrowSm';
import Modal from 'react-modal';
import {
    KontentStringValue,
    KontentRichText,
} from '../../../types/KontentProps';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import CloseButton from '../Global/CloseButton';
//@ts-ignore
import Cookies from 'js-cookie';
import { navigate } from 'gatsby-link';
import {
    countriesList,
    euCountries,
    malaysiaCountries,
} from '../../global/countries';

interface country {
    elements: {
        country: {
            value: string;
        };
        flag: {
            value: [
                {
                    id: string;
                    elements: {
                        file: {
                            value: [
                                {
                                    url: string;
                                }
                            ];
                        };
                        icon_svg_file: {
                            value: [
                                {
                                    url: string;
                                }
                            ];
                        };
                    };
                }
            ];
        };
        language: {
            value: string;
        };
        site_url: {
            value: string;
        };
        domain_url: {
            value: string;
        };
    };
}
export interface CountrySelectProps {
    disclaimer_copy: KontentRichText;
    headline: {
        value: KontentStringValue;
    };
    introduction_copy: KontentRichText;
    cta_label: {
        value: KontentStringValue;
    };
    countries_for_selection: {
        value: country[];
    };
    navFlag?: Boolean;
}

type countryDetailsType = {
    countryCode: {
        country: string;
        language: string;
        flagUrl: string;
        siteUrl: (typeof countriesList)[number] | 'https://sunlighten.com/';
        domainUrl: string;
    }[];
};

type countryCodetype = (typeof countriesList)[number] | 'us-en';

const CountrySelectOverlay: React.FC<CountrySelectProps> = ({
    disclaimer_copy,
    headline,
    cta_label,
    introduction_copy,
    countries_for_selection,
    navFlag,
}) => {
    const pageMetadata = useContext(PageMetadataContext);
    const [ipDetails, setIpDetails] = React.useState<any>('');
    const [isActive, setActive] = React.useState(false);
    const [countryLanguages, setLan] = React.useState();
    const [flagUrl, setFlag] = React.useState();
    const [dropdownUrl, setDropdownUrl] = React.useState('');
    const [isCountrySelect, setSelectCountry] = React.useState(false);
    const [isLanSelect, setSelectLang] = React.useState(false);
    const [currentCountry, setCurrentCountry] = React.useState<null | String>(
        null
    );
    const [currentCountryLang, setCurrentCountryLang] =
        React.useState<null | String>(null);

    const splittedCountryDomainPath =
        typeof window !== 'undefined'
            ? window.location.origin.split('.').at(-1)
            : '';
    const currentDomainCountry =
        splittedCountryDomainPath != 'com' ? splittedCountryDomainPath : '';
    const getDefaultPreferedLanguage = (path: string) => {
        if (path == 'nz') {
            return 'nz-en';
        } else if (path == 'au') {
            return 'au-en';
        }
        return 'us-en';
    };
    //@ts-ignore
    const preferredLanguage: 'us-en' | (typeof countriesList)[number] =
        pageMetadata?.preferredLanguage === 'default'
            ? getDefaultPreferedLanguage(
                  splittedCountryDomainPath ? splittedCountryDomainPath : ''
              )
            : pageMetadata?.preferredLanguage?.toLowerCase();

    const traceIpLocation = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${window.location.origin}/api`)
                .then((response) => {
                    resolve(response.json());
                })
                .catch((err) => {
                    reject({ err });
                });
        });
    };

    const GetInit = async () => {
        try {
            let responseIp = await traceIpLocation();
            setIpDetails(responseIp);
        } catch (err) {
            console.error('unable to fetch ip address');
        }
    };

    React.useEffect(() => {
        GetInit();
    }, []);

    const isVercelOrLocalDomain = () => {
        const isAutoGeneratedDomain = ['vercel.app', 'localhost'].filter(
            (item) => window.location.origin.includes(item)
        );
        if (isAutoGeneratedDomain.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const currentPagePath =
        pageMetadata?.slug && pageMetadata?.pagePaths[pageMetadata?.slug];
    const countryTrees = countries_for_selection?.value;

    const handleToggle = () => {
        setActive(!isActive);
    };

    const dropdownCountryOptions: any = [];
    const language: Array<any> = countryLanguages ? [countryLanguages] : [];
    let countryDetails: countryDetailsType | {} = {};
    const getCountryUrlCode = (url: string): countryCodetype => {
        const splitValues: string[] = url.split('/');
        const splitByDots: string = url
            .split('.')
            [url.split('.').length - 1].replace('/', '');
        //handling the case when url is empty string
        //@ts-ignore
        let countryCode: countryCodetype = splitValues[splitValues.length - 1]
            ? splitValues[splitValues.length - 1] //gb-en
            : splitByDots !== 'com'
            ? splitByDots + '-en'
            : 'us-en';
        return countryCode;
    };
    let countryUrlCode: countryCodetype;
    countryTrees?.map((item) => {
        dropdownCountryOptions.push(item.elements.country.value);
        // adding country details
        countryUrlCode = getCountryUrlCode(item.elements.site_url.value);
        let imageFlag =
            item.elements.flag.value[0]?.elements?.file?.value[0]?.url;
        let svgFlagIcon =
            item.elements.flag.value[0]?.elements?.icon_svg_file?.value[0]?.url;
        let setFlagUrl = item.elements.flag?.value.length
            ? imageFlag
                ? imageFlag
                : svgFlagIcon
            : '';

        let domainUrl =
            typeof window !== 'undefined'
                ? isVercelOrLocalDomain()
                    ? window?.location.origin
                    : item.elements.domain_url.value.replace(
                          'sunlighten',
                          window?.location.host.split('sunlighten')[0] +
                              'sunlighten'
                      )
                : item.elements.domain_url.value;

        countryDetails[countryUrlCode] = {
            country: item.elements.country.value,
            language: item.elements.language.value,
            flagUrl: setFlagUrl,
            siteUrl: item.elements.site_url.value,
            domainUrl: domainUrl,
        };
    });

    let otherCountryCode: typeof countriesList | string | null = ipDetails //@ts-ignore
        ? currentDomainCountry
            ? currentDomainCountry.toLowerCase()
            : ipDetails.country_code.toLowerCase()
        : null;

    let domainCountryPath = preferredLanguage;

    const defaultCountryOptions = domainCountryPath
        ? countryDetails[domainCountryPath]?.country
        : '';
    const [defaultLaungauageOptions, setDefaultLaungauageOptions] =
        React.useState(
            domainCountryPath
                ? countryDetails[domainCountryPath]?.language
                : null
        );
    const defaultFlagUrl = domainCountryPath
        ? countryDetails[domainCountryPath]?.flagUrl
        : '';
    let domainUrl = domainCountryPath
        ? countryDetails[domainCountryPath]?.domainUrl
        : '';

    if (!currentDomainCountry) {
        if (euCountries.indexOf(otherCountryCode) >= 0) {
            domainCountryPath = 'gb-en';
        } else if (malaysiaCountries.indexOf(otherCountryCode) >= 0) {
            domainCountryPath = 'my-en';
        }
    }

    const setCookieIfQueryParams = () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let paramsCountrtyCode = params.cc;
        if (paramsCountrtyCode) {
            Cookies.set('countryCode', paramsCountrtyCode);
            Cookies.set('firstVisit', 'false');
        }
    };

    const checkPrivacyPolicy = () => {
        const privacyPolicyPagePath =
            window.location.pathname.split('/').slice(-1)[0].toLowerCase() ==
            'privacy-policy'
                ? '/privacy-policy'
                : '';
        return privacyPolicyPagePath;
    };

    React.useEffect(() => {
        //contains redirection logic based on preferenced country
        setCookieIfQueryParams();
        const firstVisit = !Cookies?.get('firstVisit');
        const cookieCountryCode: typeof countriesList | 'us-en' | undefined =
            Cookies?.get('countryCode');
        const pagePathIfPrivacyPolicy = checkPrivacyPolicy();

        if (firstVisit && ipDetails) {
            Cookies.set('firstVisit', 'false');
            if (countriesList.indexOf(`${otherCountryCode}-en`) > -1) {
                domainCountryPath = `${otherCountryCode}-en`;
            }
            if (domainCountryPath === preferredLanguage) return;
            Cookies.set('countryCode', domainCountryPath);

            if (process.env.LOCAL || window.location.origin === domainUrl) {
                //when making production build locally add LOCAL=TRUE in production env
                const linkUrl =
                    domainCountryPath &&
                    buildLink(
                        ['au-en', 'nz-en'].indexOf(domainCountryPath) > -1
                            ? countryDetails[domainCountryPath]?.domainUrl +
                                  pagePathIfPrivacyPolicy
                            : countryDetails[domainCountryPath]?.siteUrl +
                                  pagePathIfPrivacyPolicy
                    );

                linkUrl && navigate(linkUrl);
            } else {
                const countrycode =
                    ['us-en', 'au-en', 'nz-en'].indexOf(domainCountryPath) > -1
                        ? ''
                        : `/${domainCountryPath}`;
                const PathToNavigate =
                    domainUrl +
                    countrycode +
                    currentPagePath +
                    '?cc=' +
                    domainCountryPath;
                window.location.replace(PathToNavigate);
            }
        } else if (cookieCountryCode) {
            if (cookieCountryCode === preferredLanguage) return;
            else {
                const countrycode =
                    ['us-en', 'au-en', 'nz-en'].indexOf(cookieCountryCode) > -1
                        ? ''
                        : `/${cookieCountryCode}`;
                if (
                    process.env.LOCAL ||
                    window.location.origin ===
                        countryDetails[cookieCountryCode].domainUrl
                ) {
                    const linkUrl =
                        cookieCountryCode &&
                        buildLink(
                            countryDetails[cookieCountryCode]?.domainUrl +
                                countrycode +
                                pagePathIfPrivacyPolicy
                        );
                    linkUrl && navigate(linkUrl);
                } else {
                    const PathToNavigate =
                        countryDetails[cookieCountryCode].domainUrl +
                        countrycode +
                        pagePathIfPrivacyPolicy;
                    window.location.replace(PathToNavigate);
                }
            }
        }
    }, [ipDetails]);

    const pageCountry =
        preferredLanguage && countryDetails[preferredLanguage]?.country;
    const pageLanguage =
        preferredLanguage && countryDetails[preferredLanguage]?.language;
    const pageFlag =
        preferredLanguage && countryDetails[preferredLanguage]?.flagUrl;

    const onChange = (event: any, selectedValue: string) => {
        if (selectedValue == 'language') {
            setSelectLang(true);
            setCurrentCountryLang(event?.value);
            if (currentCountry == null && preferredLanguage) {
                setCurrentCountry(countryDetails[preferredLanguage]);
            }
            return;
        }
        const SelectedCountry = event.value;
        countryTrees?.map((country_data: any) => {
            const { country, language, flag, site_url } = country_data.elements;
            if (country.value == SelectedCountry) {
                let imageFlag = flag.value[0]?.elements?.file?.value[0]?.url;
                let svgFlagIcon =
                    flag.value[0]?.elements?.icon_svg_file?.value[0]?.url;
                let isFlag = imageFlag ? imageFlag : svgFlagIcon;
                const lan = language?.value;
                const flagUrl = flag.value.length ? isFlag : '';
                let countryObj = Object.values(countryDetails).filter(
                    (countryDetail) =>
                        countryDetail.country.toLowerCase() ==
                            country.value.toLowerCase() &&
                        countryDetail.language.toLowerCase() ==
                            lan.toLowerCase()
                )[0];
                let linkURL = site_url?.value;

                if (
                    window.location.origin === countryObj?.domainUrl ||
                    isVercelOrLocalDomain()
                ) {
                    countrySiteUrl(countryObj?.siteUrl)
                        ? setDropdownUrl(countryObj?.domainUrl)
                        : isVercelOrLocalDomain()
                        ? setDropdownUrl(
                              countryDetails[linkURL].domainUrl + '/' + linkURL
                          )
                        : setDropdownUrl(countryObj?.domainUrl + '/' + linkURL);
                } else {
                    const navigatingUrlCountryCode = countrySiteUrl(
                        countryObj?.siteUrl
                    )
                        ? ''
                        : `/${countryObj.siteUrl}`;

                    let selectedCountryCode =
                        countryObj.siteUrl == 'https://sunlighten.com/'
                            ? 'us-en'
                            : countryObj.siteUrl;
                    setDropdownUrl(
                        countryDetails[selectedCountryCode].domainUrl +
                            navigatingUrlCountryCode +
                            '?cc=' +
                            selectedCountryCode
                    );
                }
                setCurrentCountryLang(lan);
                setCurrentCountry(country?.value);
                setDefaultLaungauageOptions(lan);
                setLan(lan);
                setFlag(flagUrl);
                setSelectCountry(true);
            }
        });
    };

    const countrySiteUrl = (siteUrl: string) => {
        const siteUrlCountry = ['https://sunlighten.com/', 'au-en', 'nz-en'];
        if (siteUrlCountry.includes(siteUrl)) return true;
        else return false;
    };

    const saveCountryCodeCookie = () => {
        if (currentCountry && currentCountryLang) {
            let countryObj = Object.values(countryDetails).filter(
                (countryDetail) =>
                    countryDetail.country.toLowerCase() ==
                        currentCountry.toLowerCase() &&
                    countryDetail.language.toLowerCase() ==
                        currentCountryLang.toLowerCase()
            )[0];
            ///get country code from currentCountry and currentlang
            if (countryObj.siteUrl.toLowerCase().includes('https')) {
                Cookies.set('countryCode', 'us-en');
            } else {
                Cookies.set('countryCode', countryObj.siteUrl); // new
            }
        } else if (!currentCountry && currentCountryLang) {
            let countryObj = Object.values(countryDetails).filter(
                (countryDetail) =>
                    countryDetail.country.toLowerCase() ==
                        currentCountry.toLowerCase() &&
                    countryDetail.language.toLowerCase() ==
                        currentCountryLang.toLowerCase()
            )[0];

            if (countryObj.siteUrl.toLowerCase().includes('https')) {
                Cookies.set('countryCode', 'us-en');
            } else {
                Cookies.set('countryCode', countryObj.siteUrl); // new
            }
        }
    };

    const closeModal = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setFlag(pageFlag);
        setDefaultLaungauageOptions(pageLanguage);
        setCurrentCountry(null);
        setCurrentCountryLang(null);
        setActive(false);
    };

    const doc = typeof document !== 'undefined' ? document.body : '';
    return (
        <>
            <div onClick={handleToggle} id="navigation__Country-flag">
                {navFlag ? (
                    <img src={defaultFlagUrl} alt="Country FLag" />
                ) : (
                    <div>
                        {pageCountry}-{pageLanguage}
                    </div>
                )}
            </div>

            <Modal
                isOpen={isActive}
                contentLabel="Country Select Overlay"
                onRequestClose={closeModal}
                overlayClassName={style.overlayFooter}
                appElement={doc}
            >
                <div className={style.closeButton}>
                    <CloseButton visualStyle="orange" onClick={closeModal} />
                </div>
                <div>
                    <div className={style.container}>
                        <div className={style.head}>{headline?.value}</div>

                        <div
                            className={style.subContiner}
                            style={{ marginTop: flagUrl ? '2%' : '1%' }}
                        >
                            <div className={style.flagWrapper}>
                                {
                                    <img
                                        src={flagUrl ? flagUrl : defaultFlagUrl}
                                    />
                                }
                            </div>
                            <div className={style.intro}>
                                {RichText(introduction_copy)}
                            </div>
                        </div>

                        <div className={style.form}>
                            <form>
                                <div className={style.selectsWrapper}>
                                    <div className={style.selectWrapper}>
                                        <Dropdown
                                            menuClassName={style.menuClass}
                                            placeholderClassName={
                                                style.myPlaceholderClassName
                                            }
                                            options={dropdownCountryOptions}
                                            value={defaultCountryOptions}
                                            onChange={(event) =>
                                                onChange(event, 'country')
                                            }
                                            controlClassName={
                                                isCountrySelect
                                                    ? style.selected
                                                    : style.nonSelected
                                            }
                                            arrowClassName="myArrowClassName"
                                            arrowClosed={
                                                <span className="arrow-closed">
                                                    <div
                                                        className={
                                                            style.downArrow
                                                        }
                                                    >
                                                        <SvgDownArrowSm
                                                            strokeColor="grey"
                                                            transform="180deg"
                                                        />
                                                    </div>
                                                </span>
                                            }
                                            arrowOpen={
                                                <span className="arrow-open">
                                                    <div
                                                        className={
                                                            style.upArrow
                                                        }
                                                    >
                                                        <SvgDownArrowSm
                                                            strokeColor="grey"
                                                            transform="0deg"
                                                        />
                                                    </div>
                                                </span>
                                            }
                                            placeholder="SELECT A LOCATION"
                                        />
                                    </div>
                                    <div className={style.selectWrapper}>
                                        <Dropdown
                                            options={language}
                                            value={defaultLaungauageOptions}
                                            onChange={(event) =>
                                                onChange(event, 'language')
                                            }
                                            controlClassName={
                                                isLanSelect
                                                    ? style.selected
                                                    : style.nonSelected
                                            }
                                            menuClassName={style.menuClass}
                                            placeholderClassName={
                                                style.myPlaceholderClassName
                                            }
                                            placeholder="SELECT A LANGUAGE"
                                            arrowClassName="myArrowClassName"
                                            arrowClosed={
                                                <span className="arrow-closed">
                                                    <div
                                                        className={
                                                            style.downArrow
                                                        }
                                                    >
                                                        <SvgDownArrowSm
                                                            strokeColor="grey"
                                                            transform="180deg"
                                                        />
                                                    </div>
                                                </span>
                                            }
                                            arrowOpen={
                                                <span className="arrow-open">
                                                    <div
                                                        className={
                                                            style.upArrow
                                                        }
                                                    >
                                                        <SvgDownArrowSm
                                                            strokeColor="grey"
                                                            transform="0deg"
                                                        />
                                                    </div>
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={style.intro}>
                            {RichText(disclaimer_copy)}
                        </div>
                        <div
                            onClick={saveCountryCodeCookie}
                            className={style.buttonWrapper}
                        >
                            <CtaLink
                                href={dropdownUrl}
                                visualStyle="outlineLight"
                            >
                                {cta_label?.value}
                            </CtaLink>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
function buildLink(redirectLink: string) {
    //testing redirect link
    if (redirectLink.includes('https') || redirectLink.includes('http')) {
        //means we are redirectly other sites then usa
        return redirectLink;
    }

    //assign "/" in the starting and return if other site then usa
    return '/' + redirectLink;
}

export default CountrySelectOverlay;

export const query = graphql`
    fragment CountrySelectOverlay on kontent_item_component___country_select_overlay {
        elements {
            countries_for_selection {
                value {
                    ... on kontent_item_content_item___country_sites {
                        id
                        elements {
                            country {
                                value
                            }
                            flag {
                                value {
                                    ... on kontent_item_media___icon {
                                        id
                                        elements {
                                            icon_svg_file {
                                                value {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                    ... on kontent_item_media___image {
                                        id
                                        elements {
                                            file {
                                                value {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            language {
                                value
                            }
                            site_url {
                                value
                            }
                            domain_url {
                                value
                            }
                        }
                    }
                }
            }
            cta_label {
                value
            }
            disclaimer_copy {
                value
            }
            headline {
                value
            }
            introduction_copy {
                value
            }
        }
    }
`;
